import Container from '@components/Container'
import { Pagination } from '@components/Pagination'
import SEO from '@components/SEO'
import { css, useTheme } from '@emotion/react'
import { EventsQuery } from '@graphql-types'
import { graphql, PageProps } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby-plugin-react-i18next'
import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'
import { maxWidth } from 'src/themes'

function EventComponent({
	event,
}: {
	event: EventsQuery['liveEvents']['edges'][number]['node']
}) {
	return (
		<a
			target='_blank'
			rel='noopener noreferrer'
			href={event.url!}
			css={css`
				display: flex;
				margin-bottom: 2rem;
				width: 100%;

				${maxWidth[1]} {
					flex-direction: column;
				}

				&:hover {
					text-decoration: none;
				}

				h3 {
					border: none;
					font-size: 1.2rem;
					font-weight: 600;
				}

				h4 {
					margin-top: 0;
					margin-bottom: 0.5rem;
					font-weight: 500;
					font-size: 1.5rem;
				}

				&.past {
					filter: grayscale(90%);
					opacity: 0.5;
				}
			`}>
			<div
				css={css`
					width: 300px;
					margin-right: 2rem;
				`}>
				<GatsbyImage
					image={event.logoImage?.childImageSharp?.gatsbyImageData}
					alt={event.name?.text!}
				/>
			</div>
			<div
				css={css`
					flex: 1;
					display: flex;
					flex-direction: column;
				`}>
				<h3
					css={css`
						margin: 0;
					`}>
					{event.name?.text}
				</h3>
				<p
					css={css`
						order: -1;
						margin: 0;
					`}>
					{moment(event.start!.utc).format('YYYY-MM-DD')}
				</p>
				<ReactMarkdown
					children={event.description?.text?.substr(0, 100) + '...'}
				/>
			</div>
		</a>
	)
}

export default function Events(
	props: PageProps<
		EventsQuery,
		{ currentPage: number; numEventPages: number }
	>
) {
	const { t } = useTranslation()

	return (
		<Container>
			<SEO title={t('layout:events')} keywords={[`event`]} />
			<h1>{t('layout:events')}</h1>
			<h2>{t('events.upcoming')}</h2>
			{props.data.liveEvents?.edges.map(
				(event) =>
					event.node && (
						<EventComponent
							key={event.node.url}
							event={event.node}
						/>
					)
			)}
			<h2>{t('events.past')}</h2>
			{props.data.pastEvents?.edges.map(
				(event) =>
					event.node && (
						<EventComponent
							key={event.node.url}
							event={event.node}
						/>
					)
			)}
			<Pagination
				totalPage={props.pageContext.numEventPages}
				currentPage={props.pageContext.currentPage}
				link='/events'
			/>
		</Container>
	)
}

export const query = graphql`
	fragment EventFragment on Eventbrite {
		name {
			text
		}
		description {
			text
		}
		url
		start {
			utc
		}
		end {
			utc
		}
		logoImage {
			childImageSharp {
				gatsbyImageData(width: 300, placeholder: BLURRED)
			}
		}
		status
	}

	query Events($language: String!, $skip: Int!, $limit: Int!) {
		...TranslationFragment
		liveEvents: allEventbrite(
			filter: { status: { eq: "live" }, listed: { eq: true } }
			sort: { fields: [start___utc], order: DESC }
		) {
			edges {
				node {
					...EventFragment
				}
			}
		}
		pastEvents: allEventbrite(
			filter: { listed: { eq: true }, status: { eq: "completed" } }
			limit: $limit
			skip: $skip
			sort: { fields: [start___utc], order: DESC }
		) {
			edges {
				node {
					...EventFragment
				}
			}
		}
	}
`
